import React, { useState } from "react"
import { H1 } from "../components/text/H1"
import { H2 } from "../components/text/H2"
import { H3 } from "../components/text/H3"
import Layout from "../components/Layout"
import { Modal } from "../components/Modal"
import NewsletterForm from "../components/NewsletterForm"
import Section from "../components/Section"
import SplitView from "../components/SplitView"
import QuestionIllustration from "../images/question_illustration.svg"
import SplitViewImage from "../components/SplitViewImage"
import { LinkType, OutsideLink } from "../components/text/OutsideLink"

const faqInputForSeo = [
  {
    question: `Wie wird mit sensiblen Daten umgegangen?`,
    answer: `Uns ist bewusst, dass es sich bei den in unserer App gesammelten Daten
          um hoch sensible Daten handelt. Daher haben wir auf zwei Dinge
          besonders geachtet: Es soll nicht möglich sein, aus den Daten die Nutzenden zu
            ermitteln.
          Daten sollen technisch maximal geschützt sein.
          Um keine Rückschlüsse auf Sie zu ermöglichen wird an
          keiner Stelle eine Mail-Adresse, Telefonnummer, ein
          Name oder eine Adresse benötigt.
          Das heißt:
          Niemand kann herausfinden, dass Sie die nutzende Person der App
          sind.
          Die Daten sind nach neuesten Standards und Methoden abgesichert.
          Login Daten und App-Daten liegen z.B. auf verschiedenen Systemen. Wer
          die App-Daten hat, hat die Login Daten nicht und umgekehrt.
          Damit auch Therapeut*innen in der App keine zuordenbaren Daten von
          Patient*innen generieren, kann und sollen Therapeut*innen statt des
          Namens der Nutzenden ein Chiffre verwenden.
          Der Verzicht auf persönliche Daten ist auch der Grund, warum
          Sie einen Rücksetzungscode erhalten. Heben Sie diesen gut auf, er ist
          die einzige Möglichkeit Ihr Passwort zurückzusetzen!
          Damit Google oder Apple keine Rückschlüsse auf die Nutzung
          unserer App ziehen können, verzichten wir darauf unsere App im App-
          oder Playstore anzubieten.
          Sie sehen:
          wir nehmen den Datenschutz extrem ernst und bei uns sind Ihre Daten
          gut aufgehoben.`,
  },
  {
    question: `Kann man die App auch ohne Therapeut*in nutzen?`,
    answer: `Nein, die App ist spezifisch für die Zusammenarbeit während einer Psychotherapie entwickelt 
    und ist daher auch nur in Absprache mit einem/r Psychotherapeut*in nutzbar. 
    Falls Sie sich bereits in Therapie befinden, 
    besprechen Sie dies gerne mit Ihrem/r Psychotherapeut*in.`,
  },
  {
    question: `Wie viel soll die App kosten?`,
    answer: `Wir möchten unsere App für jeden zugänglich machen, unabhängig von
        finanziellen Möglichkeiten.
        Daher streben wir an unsere App als Digitale Gesundheitsanwendung (DiGA)
        durch das DiGA Prüfverfahren des Bundesinstitut für Arzneimittel und
        Medizinprodukte anerkennen zu lassen. Sobald wir das geschafft haben,
        ist es möglich unsere App zu verschreiben oder bei der Krankenkasse zu
        beantragen. Dadurch können wir unsere App durchgängig kostenfrei
        anbieten, da die Krankenkassen für die Kosten aufkommen.
        Bis wir dies erreicht haben, wird die App
        kostenlos verfügbar sein.`,
  },
  {
    question: `Gibt es auch vorgefertigte Inhalte für Therapeut*innen?`,
    answer: `Anfangs wird es noch keine vorgefertigten Inhalte für Therapeut*innen
        geben.
        Wir streben aber an, dass Therapeut*innen die Möglichkeit haben, ihre
        Inhalte anderen Therapeut*innen zur Verfügung zu stellen.
        Damit bei diesen Inhalten aber eine hohe Qualität
        gewährleistest wird, möchten wir diese durch ein Expertenteam
        überprüfen. Sobald diese überprüft wurden, können alle Therapeut*in die
        Inhalte für die eigenen Patient*innen nutzen und individualisieren.
        Außerdem möchten wir zusätzlich bestehende therapeutische Hausaufgaben
        von renommierten Verlägen bereitstellen.`,
  },
  {
    question: `Wie erfahre ich von Neuigkeiten?`,
    answer: `
        Sie möchten erfahren, wenn es etwas Neues gibt?
        Dann tragen Sie sich in unseren Newsletter ein oder
        sehen Sie sich Neuigkeiten in der App unter
        Einstellungen->Neuigkeiten" an.`,
  },
  {
    question: `Mit welchen Geräten kann ich die App nutzen?`,
    answer: `Zur Nutzung werden ein internetfähiges Endgerät, ein Internetzugang
        sowie ein aktueller Browser benötigt (Chrome 89, Edge
        87, Firefox 88, Safari 14).
        Dabei ist es egal, ob Sie die App mit Ihrem{" "}
        Smartphone, PC oder Tablet öffnen.
        Die Funktionalität kann aber je Endgerät variieren, da iOS (iPhone und
        iPad) manche Funktionalität derzeit leider nicht unterstützt - diese
        sind aber nicht essentiell zur Nutzung der App.`,
  },
]

const input = [
  {
    question: `Wie wird mit sensiblen Daten umgegangen?`,
    answer: (
      <div>
        <p>
          Uns ist bewusst, dass es sich bei den in unserer App gesammelten Daten
          um hoch sensible Daten handelt. Daher haben wir auf zwei Dinge
          besonders geachtet:
        </p>
        <ul className="list-inside list-decimal my-2">
          <li>
            Es soll nicht möglich sein, aus den Daten die Nutzenden zu
            ermitteln.
          </li>
          <li>Daten sollen technisch maximal geschützt sein.</li>
        </ul>
        <p>
          Um keine Rückschlüsse auf Sie zu ermöglichen wird an{" "}
          <strong>keiner Stelle</strong> eine Mail-Adresse, Telefonnummer, ein
          Name oder eine Adresse benötigt. <br />
          Das heißt:
          <br />
          <br />
          <strong>
            Niemand kann herausfinden, dass Sie die nutzende Person der App
            sind.
          </strong>
          <br />
          <br />
          Die Daten sind nach neuesten Standards und Methoden abgesichert.
          <br />
          Login Daten und App-Daten liegen z.B. auf verschiedenen Systemen. Wer
          die App-Daten hat, hat die Login Daten nicht und umgekehrt.
          <br />
          <br />
          Damit auch Therapeut*innen in der App keine zuordenbaren Daten von
          Patient*innen generieren, kann und sollen Therapeut*innen statt des
          Namens der Nutzenden ein <strong>Chiffre</strong> verwenden.
          <br /> Der Verzicht auf persönliche Daten ist auch der Grund, warum
          Sie einen Rücksetzungscode erhalten. Heben Sie diesen gut auf, er ist
          die einzige Möglichkeit Ihr Passwort zurückzusetzen!
          <br /> Damit Google oder Apple keine Rückschlüsse auf die Nutzung
          unserer App ziehen können, verzichten wir darauf unsere App im App-
          oder Playstore anzubieten.
          <br /> Sie sehen:{" "}
          <strong>
            wir nehmen den Datenschutz extrem ernst und bei uns sind Ihre Daten
            gut aufgehoben.
          </strong>
        </p>
      </div>
    ),
  },
  {
    question: `Kann man die App auch ohne Therapeut*in nutzen?`,
    answer: (
      <p>
        Nein, die App ist spezifisch für die Zusammenarbeit während einer
        Psychotherapie entwickelt und ist daher auch{" "}
        <strong>nur in Absprache mit einem/r Psychotherapeut*in</strong>{" "}
        nutzbar.
        <br />
        Falls Sie sich bereits in Therapie befinden, besprechen Sie dies gerne
        mit Ihrem/r Psychotherapeut*in.
      </p>
    ),
  },
  {
    question: `Wie viel soll die App kosten?`,
    answer: (
      <p>
        Wir möchten unsere App für jeden zugänglich machen, unabhängig von
        finanziellen Möglichkeiten. <br />
        Daher streben wir an unsere App als Digitale Gesundheitsanwendung (DiGA)
        durch das DiGA Prüfverfahren des Bundesinstitut für Arzneimittel und
        Medizinprodukte anerkennen zu lassen. Sobald wir das geschafft haben,
        ist es möglich unsere App zu verschreiben oder bei der Krankenkasse zu
        beantragen. <br />
        Dadurch können wir unsere App <strong>
          durchgängig kostenfrei
        </strong>{" "}
        anbieten, da die Krankenkassen für die Kosten aufkommen.
        <br />
        Bis wir dies erreicht haben, wird die App{" "}
        <strong>kostenlos verfügbar</strong> sein.
      </p>
    ),
  },
  {
    question: `Gibt es auch vorgefertigte Inhalte für Therapeut*innen?`,
    answer: (
      <p>
        Anfangs wird es noch keine vorgefertigten Inhalte für Therapeut*innen
        geben. <br />
        Wir streben aber an, dass Therapeut*innen die Möglichkeit haben, ihre
        Inhalte anderen Therapeut*innen zur Verfügung zu stellen. <br />
        Damit bei diesen Inhalten aber eine <strong>hohe Qualität</strong>{" "}
        gewährleistest wird, möchten wir diese durch ein Expertenteam
        überprüfen. Sobald diese überprüft wurden, können alle Therapeut*in die
        Inhalte für die eigenen Patient*innen nutzen und individualisieren.
        <br />
        <br />
        Außerdem möchten wir zusätzlich bestehende therapeutische Hausaufgaben
        von renommierten Verlägen bereitstellen.
      </p>
    ),
  },
  {
    question: `Wie erfahre ich von Neuigkeiten?`,
    answer: (
      <p>
        Sie möchten erfahren, wenn es etwas Neues gibt? <br />
        Dann tragen Sie sich in unseren <strong>Newsletter</strong> ein oder
        sehen Sie sich <strong>Neuigkeiten in der App</strong> unter
        "Einstellungen-{">"}
        Neuigkeiten" an.
      </p>
    ),
  },
  {
    question: `Mit welchen Geräten kann ich die App nutzen?`,
    answer: (
      <p>
        Zur Nutzung werden ein internetfähiges Endgerät, ein Internetzugang
        sowie ein <strong>aktueller Browser</strong> benötigt (Chrome 89, Edge
        87, Firefox 88, Safari 14).
        <br />
        Dabei ist es egal, ob Sie die App mit Ihrem{" "}
        <strong>Smartphone, PC oder Tablet</strong> öffnen.
        <br />
        Die Funktionalität kann aber je Endgerät variieren, da iOS (iPhone und
        iPad) manche Funktionalität derzeit leider nicht unterstützt - diese
        sind aber nicht essentiell zur Nutzung der App.
      </p>
    ),
  },
]

export interface FAQProps {}

const FAQ: React.SFC<FAQProps> = () => {
  const [showForm, setShowForm] = useState(false)
  const [openFAQ, setOpenFAQ] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ])
  return (
    <Layout faq={faqInputForSeo}>
      <Section>
        <div className="max-w-3xl mx-auto">
          <H1 text="Häufige Fragen" className="text-center" />
          {input.map((item, index) => (
            <div className="p-4 mb-2 border-b">
              <button
                className="flex items-center w-full text-left focus:outline-none"
                onClick={() => {
                  const array = [...openFAQ]
                  array[index] = !array[index]
                  setOpenFAQ(array)
                }}
              >
                <span className="text-2xl mr-4">
                  {openFAQ[index] ? "-" : "+"}
                </span>
                <span className="flex-grow font-semibold">{item.question}</span>
              </button>
              <div
                className={`mt-2 ${
                  openFAQ[index] ? "" : "hidden"
                } shadow p-4 text-gray-600`}
                mb={0}
              >
                {item.answer}
                {index === 4 && (
                  <>
                    <br />
                    <button
                      className="inline-block py-3 px-6 leading-none text-white bg-primary-600 hover:bg-primary-700 font-semibold rounded shadow"
                      onClick={() => {
                        setShowForm(!showForm)
                      }}
                    >
                      In den Newsletter eintragen
                    </button>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
        <Modal
          show={showForm}
          setShow={() => {
            setShowForm(!showForm)
          }}
          content={
            <NewsletterForm
              onCancel={() => {
                setShowForm(!showForm)
              }}
            />
          }
        />
      </Section>

      <SplitView
        background
        heading={<H2 text="Weitere Fragen" className="text-center" mb={16} />}
        elementLeft={
          <SplitViewImage
            src={QuestionIllustration}
            alt="Weitere Fragen Illustration"
          />
        }
        elementRight={
          <>
            <H3 text="Ihre Frage konnte noch nicht beantwortet werden?" />
            <p className="mb-8 lg:text-left text-center">
              Dann schreiben Sie uns gerne eine Mail oder nutzen Sie unseren
              Live Chat (rechts unten) und wir beantworten Ihre Frage zeitnah.
            </p>
            <ul>
              <li className="mb-4 flex items-center justify-center lg:justify-start">
                <svg
                  className="inline-block w-6 h-6 mr-3 text-primary-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  ></path>
                </svg>
                <span>
                  <OutsideLink
                    href="jonas@diepsychotherapieapp.de"
                    type={LinkType.mail}
                  />
                </span>
              </li>
            </ul>
          </>
        }
      />
    </Layout>
  )
}

export default FAQ
